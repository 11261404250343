import React, { useState, useEffect, useCallback, KeyboardEvent, useRef, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { upsertJournalEntry } from '../../api';
import { useGeneralContext } from '../../context';
import simpleLogo from '../../resources/logo-simple.png';
import { TagList } from '../../components/entryTags';
import { browserTimestampJournalEntryFormat, getDayDateTimeFromEntryDateTitle } from '../../util/dateTime';
import useDebounce from '../../hooks/useDebounce';
import { Colors, DEFAULT_ASSISTANT } from './types';
import { Home, X, Twitter, ArrowLeft } from 'react-feather';
import toast from 'react-hot-toast';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EntryBlock, EntryState, EntryStates, isEntryStates } from '../../components/calendar/types';
import { AxiosError } from 'axios';
import MeatballMenu from './MeatballMenu';
import AutoResizeTextBox from './AutoResizeTextBox';
import { cleanEntryState, getWordCount } from './entryStateUtils';
import Badge from 'react-bootstrap/Badge';
import MentorSelector from './MentorSelector';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import '../../css/App.css';
import './singleEntry.css';

const ONE_SECOND = 1000;
const AUTOSAVE_DELAY = ONE_SECOND * 60;

const SingleEntry = () => {
  const location = useLocation<{
    entryState: EntryStates;
  }>();

  const [entries, setEntries] = useState<EntryStates>(
    isEntryStates(location?.state?.entryState)
      ? (location?.state?.entryState as EntryStates).map((e) => ({
          ...e,
          blocks: cleanEntryState(e.blocks)
        }))
      : []
  );

  const params = useParams<{ persona: string }>();

  const generalContext = useGeneralContext();
  const { entryBackgroundColor, setEntryBackgroundColor, nuggets } = generalContext;
  const entryStates = entries.map((entry) => entry.blocks);

  const [wordCount, setWordCount] = useState<number>(
    entryStates.length > 0
      ? entryStates.reduce((prev, curr) => {
          return prev + getWordCount(curr);
        }, 0)
      : 0
  );

  const autoGeneratedTags = useMemo(() => {
    return nuggets
      .filter((nug) => entries.map((e) => e.name).includes(nug.entryName))
      .map((n) => n.tags)
      .flat();
  }, [entries, nuggets]);

  const debouncedEntryState = useDebounce<string>(JSON.stringify(entries), AUTOSAVE_DELAY);

  const initialRender = useRef(true);

  const history = useHistory();
  const [entrySaving, setEntrySaving] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [journalAssistant, setJournalAssistant] = useState(!!params?.persona);
  const [assistantThinking, setAssistantThinking] = useState<null | number>(null);
  const [mentor, setMentor] = useState<string>(
    params?.persona?.replace(/_/g, ' ') || localStorage.getItem('lastMentor') || DEFAULT_ASSISTANT
  );
  const [textAreaFocusIndex, setTextAreaFocusIndex] = useState<number | null>(null);
  const [blur, setBlur] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [suggestedMentors, setSuggestedMentors] = useState<string[]>(
    localStorage.getItem('savedMentors')
      ? JSON.parse(localStorage.getItem('savedMentors') as string)
      : [DEFAULT_ASSISTANT]
  );
  const [showPremiumPaywall, setShowPremiumPaywall] = useState(false);

  useEffect(() => {
    setWordCount(
      entryStates.length > 0
        ? entryStates.reduce((prev, curr) => {
            return prev + getWordCount(curr);
          }, 0)
        : 0
    );
  }, [entryStates]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.querySelector('body')?.scrollTo(0, 0);
  }, []);

  const updateJournalEntry = useCallback(
    async (params: { blocks: EntryBlock[]; imported: boolean; creationDateTitle: string; embedEntry: boolean }) => {
      const { blocks, imported, creationDateTitle, embedEntry } = params;

      setEntrySaving(true);
      try {
        await upsertJournalEntry({
          value: JSON.stringify({ blocks, imported }),
          creationDateTitle,
          embedEntry
        });
      } catch (error: any) {
        console.error(error);
        if (error instanceof AxiosError && error?.response?.status === 401) {
          window.alert('Unable to save because you have been logged out.');
        }
      }
      setIsSaved(true);
      setEntrySaving(false);
    },
    []
  );

  const saveEntriesAsync = useCallback(
    async (entries: EntryStates, embedEntry: boolean) => {
      for (const entry of entries) {
        await updateJournalEntry({
          embedEntry,
          blocks: entry.blocks,
          creationDateTitle: entry.name,
          imported: entry.imported
        });
      }
    },
    [updateJournalEntry]
  );

  // catch all navigation away
  useEffect(() => {
    const unblock = history.block(({ pathname }: { pathname: any }) => {
      toast
        .promise(saveEntriesAsync(entries, true), {
          loading: 'Saving',
          success: 'Entry Saved',
          error: 'Error saving entry'
        })
        .then(() => {
          // Unblock the navigation.
          unblock();
          // retry the pagination
          history.push(pathname);
        });
    });
    return unblock;
  }, [history, entries, saveEntriesAsync]);

  // save entry when text changes
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const debouncedEntries = JSON.parse(debouncedEntryState);
    saveEntriesAsync(debouncedEntries, false);
  }, [debouncedEntryState, saveEntriesAsync]);

  // cause browser to alert on page reload, navigation to new website, or closing of tab
  useEffect(() => {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
      }
      return ''; // Legacy method for cross browser support
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  if (entries.length === 0) {
    setEntries([
      {
        blocks: [
          {
            role: 'user',
            content: ''
          }
        ],
        imported: false,
        name: browserTimestampJournalEntryFormat(new Date()),
        tags: []
      }
    ]);
  }

  // handle cmd+s
  const handleKeyDown = (event: KeyboardEvent<HTMLImageElement>) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === 's') {
      event.preventDefault();
      if (entrySaving) {
        return;
      }
      if (isSaved) {
        return toast.success('Entry Saved');
      }
      setEntrySaving(true);
      toast
        .promise(saveEntriesAsync(entries, false), {
          loading: 'Saving',
          success: 'Entry Saved',
          error: 'Error saving entry'
        })
        .then(() => {
          setEntrySaving(false);
          setIsSaved(true);
        });
    }
  };

  return (
    <div
      onKeyDown={handleKeyDown}
      style={{ backgroundColor: entryBackgroundColor, transition: 'background-color 600ms' }}
      className={'single-entry__container'}
    >
      <PremiumUpsellModal
        showModal={showPremiumPaywall}
        setShowModal={setShowPremiumPaywall}
        message={`Start a FREE Premium trial to continue the conversation with ${mentor}!`}
      />
      <div className="single-entry__wrapper">
        <OverlayTrigger placement="right" overlay={backButtonTooltip}>
          <button
            onClick={async () => {
              history.push(location?.state?.returnPath || '/home');
            }}
            style={{ backgroundColor: entryBackgroundColor, transition: 'background-color 600ms' }}
            className={`${blur ? 'blur' : ''} back-button upper-left-fixed`}
          >
            {location?.state?.returnPath ? (
              <ArrowLeft className="back-button__icon" />
            ) : (
              <Home className="back-button__icon" />
            )}
          </button>
        </OverlayTrigger>
        <MeatballMenu
          journalAssistant={journalAssistant}
          setJournalAssistant={setJournalAssistant}
          setEntryBackgroundColor={setEntryBackgroundColor}
          setBlur={setBlur}
          onUpgradeClick={() => {
            history.push({
              pathname: '/settings',
              state: { destination: 'choose-plan' }
            });
          }}
        />
        <div className="entry-container">
          {entries &&
            entries.map((entry, index) => {
              if (!entry.blocks) {
                return null;
              }
              return [
                ...entry.blocks.map((block, i) => {
                  const { day, date, time } = getDayDateTimeFromEntryDateTitle(entry.name);
                  console.log(`got day ${day} date ${date} time ${time} from entry name ${entry.name}`);
                  return (
                    <div key={`${block.role}-${i}`}>
                      {i === 0 && (
                        <SingleEntryTitleHeader
                          key={`imported-header-${i}`}
                          entryBackgroundColor={entryBackgroundColor}
                          blur={blur}
                          entryTagStrings={entry.tags}
                          autoGeneratedTags={autoGeneratedTags}
                          setTags={(tags: string[]) => {
                            const newEntry: EntryState = {
                              ...entries[index],
                              tags
                            };
                            const newEntries: EntryStates = [
                              ...entries.slice(0, index),
                              newEntry,
                              ...entries.slice(index + 1, entries.length)
                            ];
                            setEntries(newEntries);
                          }}
                          entryDateTitle={entry.name}
                          day={day}
                          date={date}
                          time={time}
                          imported={entry.imported}
                          selectedText={selectedText}
                          journalAssistant={journalAssistant}
                          mentor={mentor}
                          setMentor={setMentor}
                          suggestedMentors={suggestedMentors}
                          setSuggestedMentors={setSuggestedMentors}
                          entryIndex={index}
                        />
                      )}
                      {block.role === 'assistant' ? (
                        <AssistantEntryBlock
                          blur={blur}
                          entryBlock={block}
                          entryBackgroundColor={entryBackgroundColor}
                          i={i}
                          entry={entry}
                          index={index}
                          setEntries={setEntries}
                        />
                      ) : (
                        <AutoResizeTextBox
                          index={i}
                          key={`textarea-${i}`}
                          blocks={entry.blocks}
                          entryBackgroundColor={entryBackgroundColor}
                          setEntryState={(entryState: EntryBlock[]) => {
                            setEntries((currentEntryState) => [
                              ...currentEntryState.slice(0, index),
                              {
                                ...currentEntryState[index],
                                blocks: entryState
                              },
                              ...currentEntryState.slice(index + 1, currentEntryState.length)
                            ]);
                          }}
                          setIsSaved={setIsSaved}
                          journalAssistant={journalAssistant}
                          setAssistantThinking={setAssistantThinking}
                          assistantThinking={assistantThinking}
                          textAreaFocusIndex={textAreaFocusIndex}
                          setTextAreaFocusIndex={setTextAreaFocusIndex}
                          blur={blur}
                          setSelectedText={setSelectedText}
                          mentor={mentor}
                          entryIndex={index}
                          setShowPremiumPaywall={setShowPremiumPaywall}
                        />
                      )}
                    </div>
                  );
                }),
                ...(assistantThinking === index
                  ? [
                      <img
                        style={{ opacity: '0.6', height: '24px', width: '24px' }}
                        alt="logo"
                        className="logo-img blinking-element"
                        src={simpleLogo}
                      ></img>
                    ]
                  : [])
              ];
            })}
        </div>
        <div className="single-entry__info bottom-right-fixed">
          <OverlayTrigger placement="top" overlay={wordCountTooltip}>
            <div
              className={`${blur ? 'blur' : ''} ${
                entryBackgroundColor === Colors.Dark ? 'text--primary-100' : 'text--primary-900'
              } text text--caption`}
            >
              {`${wordCount} ${wordCount === 1 ? 'word' : 'words'}`}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

const AssistantEntryBlock = (props: {
  blur: boolean;
  entryBlock: EntryBlock;
  entryBackgroundColor: string;
  i: number;
  entry: EntryState & { tags: string[] };
  index: number;
  setEntries: React.Dispatch<React.SetStateAction<EntryStates>>;
}) => {
  const { blur, entryBlock, entryBackgroundColor, i, entry, index, setEntries } = props;
  return (
    <div
      key={`${entryBlock.role}-${i}`}
      className={`${blur ? 'blur' : ''} single-entry__assistant-field text text--regular-weight text--paragraph-2 ${
        entryBackgroundColor === Colors.Dark
          ? 'text--neutral-50 single-entry__assistant-field--dark-bg'
          : entryBackgroundColor === Colors.White
          ? 'text--primary-900 single-entry__assistant-field--light-bg'
          : 'text--primary-900 single-entry__assistant-field--neutral-bg'
      }`}
    >
      <button
        className={`jumble-bot-content__delete-content-btn text text--caption`}
        onClick={() => {
          if (window.confirm('Delete this Jumble-Bot prompt?')) {
            const newEntryState = cleanEntryState([
              ...entry.blocks.slice(0, i),
              ...entry.blocks.slice(i + 1, entry.blocks.length)
            ]);
            setEntries((currentEntryState: EntryStates) => [
              ...currentEntryState.slice(0, index),
              {
                ...currentEntryState[index],
                blocks: newEntryState
              },
              ...currentEntryState.slice(index + 1, currentEntryState.length)
            ]);
          }
        }}
      >
        <X size={18} />
      </button>
      {entryBlock.content}
    </div>
  );
};

const SingleEntryTitleHeader = (params: {
  entryBackgroundColor: string;
  blur: boolean;
  day: string;
  date: string;
  time: string;
  entryTagStrings: string[];
  autoGeneratedTags: string[];
  entryDateTitle: string;
  setTags: (tags: string[]) => void;
  selectedText: string;
  imported?: boolean;
  sticky?: boolean;
  journalAssistant: boolean;
  mentor: string;
  setMentor: React.Dispatch<React.SetStateAction<string>>;
  suggestedMentors: string[];
  setSuggestedMentors: React.Dispatch<React.SetStateAction<string[]>>;
  entryIndex: number;
}) => {
  const {
    entryBackgroundColor,
    blur,
    day,
    date,
    time,
    imported,
    sticky,
    entryTagStrings,
    autoGeneratedTags,
    entryDateTitle,
    setTags,
    selectedText,
    journalAssistant,
    mentor,
    setMentor,
    suggestedMentors,
    setSuggestedMentors,
    entryIndex
  } = params;

  return (
    <div
      className={`single-entry__sticky-header-container ${sticky && 'sticky'}`}
      style={{ backgroundColor: entryBackgroundColor, transition: 'background-color 600ms' }}
    >
      <div className={'single-entry__header-container'}>
        <div
          className={`${blur ? 'blur' : ''} single-entry__item-date-string text text--bold text--paragraph-1 ${
            entryBackgroundColor === Colors.Dark ? 'text--primary-100' : 'text--primary-900'
          }`}
        >
          {`${day}, ${date} - ${time}`}
        </div>
        {imported && (
          <Badge
            className={blur ? 'blur' : ''}
            style={{ fontSize: '8px' }}
            bg={entryBackgroundColor === Colors.White ? 'dark' : 'light'}
            text={entryBackgroundColor === Colors.White ? 'light' : 'dark'}
          >
            imported
          </Badge>
        )}
        {journalAssistant && entryIndex === 0 && (
          <MentorSelector
            mentor={mentor}
            setMentor={setMentor}
            setSuggestedMentors={setSuggestedMentors}
            suggestedMentors={suggestedMentors}
          />
        )}
        {selectedText && (
          <OverlayTrigger placement="right" overlay={tweetItTooltip}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://twitter.com/intent/tweet?text=${encodeURI(selectedText.slice(0, 215))}${
                selectedText.length > 215 ? '...' : ''
              }%0A%0Awritten%20with&url=https://jumblejournal.org?lang=en&hashtags=journalEveryDay,journaling`}
              className="twitter-share-button"
              data-show-count="false"
            >
              <div className="flex-row-start flex-gap-4 text text--caption">
                <Twitter size={14} />
                Tweet
              </div>
            </a>
          </OverlayTrigger>
        )}
      </div>
      <TagList
        entryTagStrings={entryTagStrings}
        creationDate={entryDateTitle}
        darkMode={entryBackgroundColor === Colors.Dark}
        blur={blur}
        setTags={setTags}
        autoGeneratedTags={autoGeneratedTags}
      />
    </div>
  );
};

const backButtonTooltip = <Tooltip id="back-button-tooltip">Save and return to home screen</Tooltip>;

const wordCountTooltip = <Tooltip id="word-count-tooltip">500 words is a great target! 💪</Tooltip>;

const tweetItTooltip = <Tooltip id="word-count-tooltip">Share the highlighted text on Twitter</Tooltip>;
export default SingleEntry;
