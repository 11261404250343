import { Api } from '../api';
import { getDayMonthYearFromDate } from '../util/dateTime';

export const retrieveNuggets = async () => {
  try {
    const startStr = `20220101000000000`;
    const today = getDayMonthYearFromDate(new Date());
    const endStr = `${today.year}${(today.month + 1).toString().padStart(2, '0')}${(today.day + 1)
      .toString()
      .padStart(2, '0')}000000000`;
    const response = await Api.get(`/nuggets?start=${startStr}&end=${endStr}`);
    if (!response || !response.data) {
      return [];
    }
    const nuggets = response.data;
    return nuggets;
  } catch (error) {
    window.alert('Failed to retrieve insights');
    return [];
  }
};
