import React, { useMemo, useState } from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import {
  getDayDateTimeFromEntryDateTitle,
  getDayMonthYearFromDate,
  daysAhead,
  getThreeLetterMonthFromIndex,
  daysAgo
} from '../../util/dateTime';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { Nugget, Entry, CATEGORY_LABELS, areNuggets } from '../../views/retrospective/types';
import '../../css/App.css';
import './grid.css';
import '../../views/exploration/chatWithJournal.css';
import '../../views/retrospective/retrospective.css';
import JumbleModal from '../jumbleModal/JumbleModal';
import { useMediaQuery } from 'react-responsive';
import { loadExistingEntry } from '../calendar/calendar';
import { EntryStates, isEntryStates } from '../calendar/types';
import { useHistory } from 'react-router-dom';
import { useGeneralContext } from '../../context';
import { DayMonthYear } from './types';

export const WeeklyColumnsGrid = (props: {
  gridValues: (Nugget | Entry)[];
  dateRangeSelectorOnTop?: true;
  tagFilter: string[];
  handleClick?: () => void;
  range: { start: DayMonthYear; end: DayMonthYear };
  setRange: React.Dispatch<React.SetStateAction<{ start: DayMonthYear; end: DayMonthYear }>>;
  weeksToDisplay: number;
}) => {
  const { gridValues, handleClick, dateRangeSelectorOnTop, tagFilter, range, setRange, weeksToDisplay } = props;

  const [modalContent, setModalContent] = useState<EntryStates>([]);
  const [itemLoading, setItemLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const columns = [];
  let columnStartDate = new Date(range.start.year, range.start.month, range.start.day);
  const columnStartDateDayOfWeek = columnStartDate.getDay();
  let columnEndDate = daysAhead(7 - columnStartDateDayOfWeek, columnStartDate);

  const updateRange = (direction: 'fwd' | 'back') => {
    if (direction === 'fwd') {
      const newStart = new Date(range.end.year, range.end.month, range.end.day);
      newStart.setDate(newStart.getDate() + 1);
      const newEnd = new Date(newStart.getTime());
      newEnd.setDate(newEnd.getDate() + weeksToDisplay * 7);
      setRange({
        start: getDayMonthYearFromDate(newStart),
        end: getDayMonthYearFromDate(newEnd)
      });
      return;
    }
    // back
    const newEnd = new Date(range.start.year, range.start.month, range.start.day);
    newEnd.setDate(newEnd.getDate() - 1);
    const newStart = new Date(newEnd.getTime());
    newStart.setDate(newStart.getDate() - weeksToDisplay * 7);
    setRange({
      start: getDayMonthYearFromDate(newStart),
      end: getDayMonthYearFromDate(newEnd)
    });
  };

  const endRangeIsCurrent = () => {
    const today = new Date();
    const { day, month, year } = range.end;
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    const currentDayOfMonth = today.getDate();
    return (
      year > currentYear ||
      (year === currentYear && month > currentMonth) ||
      (year === currentYear && month === currentMonth && day >= currentDayOfMonth)
    );
  };

  const GridHeader = () => {
    return (
      <div
        className={`w-100 flex-row-centered flex-gap-8 text text--bold text--paragraph-3 ${
          dateRangeSelectorOnTop ? 'margin-bottom-16' : 'margin-top-16'
        }`}
      >
        <button
          className="retro--button-change-range"
          onClick={() => {
            updateRange('back');
          }}
        >
          <ChevronLeft size={18} />
        </button>
        {`${getThreeLetterMonthFromIndex(range.start.month)} ${range.start.year} - ${getThreeLetterMonthFromIndex(
          range.end.month
        )} ${range.end.year}`}
        <button
          disabled={endRangeIsCurrent()}
          className="retro--button-change-range"
          onClick={() => {
            updateRange('fwd');
          }}
        >
          <ChevronRight size={18} />
        </button>
      </div>
    );
  };

  for (let j = 0; j <= weeksToDisplay; j++) {
    columns.push(
      <WeekColumn
        weekIndex={j}
        key={`${columnStartDate.getTime()}-${j}`}
        gridValues={gridValues}
        tagFilter={tagFilter}
        start={getDayMonthYearFromDate(columnStartDate)}
        gridEnd={range.end}
        setShowModal={setShowModal}
        setModalContent={setModalContent}
        handleClick={handleClick}
        itemLoading={itemLoading}
        setItemLoading={setItemLoading}
      />
    );
    columnStartDate = new Date(columnEndDate.getTime());
    columnEndDate = daysAhead(7, columnEndDate);
  }
  return (
    <>
      {dateRangeSelectorOnTop && <GridHeader />}
      <div className="flex-row-centered flex-gap-4">
        <JumbleModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          modalSections={[() => getModalContent(modalContent)]}
        />
        {columns}
      </div>
      {!dateRangeSelectorOnTop && <GridHeader />}
    </>
  );
};

const WeekColumn = (props: {
  weekIndex: number;
  gridValues: (Nugget | Entry)[];
  start: DayMonthYear;
  gridEnd: DayMonthYear;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalContent: React.Dispatch<React.SetStateAction<EntryStates>>;
  itemLoading: boolean;
  setItemLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tagFilter: string[];
  handleClick?: () => void;
}) => {
  const {
    weekIndex,
    gridValues,
    start,
    gridEnd,
    setShowModal,
    setModalContent,
    itemLoading,
    setItemLoading,
    tagFilter,
    handleClick
  } = props;
  const Heading = (props: { title: string }) => {
    return (
      <div
        className="text text--paragraph-3"
        style={{ overflow: 'visible', maxWidth: '1rem', height: '1em', marginBottom: '8px' }}
      >
        {props.title}
      </div>
    );
  };
  const column =
    start.day === 1
      ? [<Heading key={`header-${weekIndex}`} title={getThreeLetterMonthFromIndex(start.month)} />]
      : [<Heading key={`header-${weekIndex}`} title="" />];
  // sunday is 0
  const startDate = new Date(start.year, start.month, start.day);
  // always 0 (sunday) except on the first column which may start later in the week
  const startDayOfWeek = startDate.getDay();
  const startMonth = start.month;
  const today = getDayMonthYearFromDate(new Date());
  for (let i = 0; i < 7; i++) {
    const indexDate = daysAhead(i - startDayOfWeek, startDate);
    const { year, month, day } = getDayMonthYearFromDate(indexDate);
    const newMonthReachedInThisColumn = i === 6 && month !== startMonth;
    if (newMonthReachedInThisColumn) {
      column[0] = <Heading key={`header-${weekIndex}-${i}`} title={getThreeLetterMonthFromIndex(month)} />;
    }
    const renderingBoxIsInTheFuture =
      today.year < year ||
      (today.year === year && today.month < month) ||
      (today.year === year && today.month === month && day > today.day);
    if (i < startDayOfWeek) {
    }

    // the first column may be incomplete, rather than starting on sunday
    if (i < startDayOfWeek) {
      column.push(<DayBoxPlaceHolder key={`placeholderbox-${weekIndex}-${i}`} />);
      continue;
    }
    if (renderingBoxIsInTheFuture) {
      column.push(<DayBoxPlaceHolder key={`placeholderbox-${weekIndex}-${i}`} />);
      continue;
    }

    // when the user scrolls back, the ends at an "arbitrary" cutoff date
    const gridEndDate = new Date(gridEnd.year, gridEnd.month, gridEnd.day);
    if (indexDate.getTime() > gridEndDate.getTime()) {
      column.push(<DayBoxPlaceHolder key={`placeholderbox-${weekIndex}-${i}`} />);
      continue;
    }

    const nugs = gridValues.filter(
      (gridValue) =>
        Number(gridValue.entryName.slice(0, 4)) === year &&
        Number(gridValue.entryName.slice(4, 6)) === month + 1 &&
        Number(gridValue.entryName.slice(6, 8)) === day
    );
    if (nugs.length === 0) {
      column.push(<DayBoxEmpty key={`emptybox-${weekIndex}-${i}`} day={day} month={month + 1} year={year} />);
      continue;
    }
    column.push(
      <DayBox
        key={`daybox-${weekIndex}-${i}`}
        gridValues={nugs}
        tagFilter={tagFilter}
        day={day}
        setShowModal={setShowModal}
        setModalContent={setModalContent}
        handleClick={handleClick}
        itemLoading={itemLoading}
        setItemLoading={setItemLoading}
      />
    );
  }
  return (
    <div key={`${start.year}${start.month}${start.day}`} className="flex-column-centered flex-gap-4 h-100">
      {column}
    </div>
  );
};

const DayBox = (props: {
  gridValues: (Nugget | Entry)[];
  day: number;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalContent: React.Dispatch<React.SetStateAction<EntryStates>>;
  itemLoading: boolean;
  setItemLoading: React.Dispatch<React.SetStateAction<boolean>>;
  tagFilter: string[];
  handleClick?: () => void;
}) => {
  const { gridValues, day, setShowModal, setModalContent, handleClick, itemLoading, setItemLoading, tagFilter } = props;
  const context = useGeneralContext();
  const { nuggets } = context;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const allNugTags = useMemo(() => {
    return nuggets
      .filter((nug) => gridValues.map((v) => v.entryName).includes(nug.entryName))
      .map((n) => n.tags)
      .flat();
  }, [gridValues, nuggets]);
  if (areNuggets(gridValues)) {
    const popover = getPopover(gridValues);
    const nug = gridValues[0];

    return (
      <OverlayTrigger overlay={popover} delay={0} placement="top">
        <button
          key={day}
          onClick={async () => {
            // retrieve journal entry
            try {
              setLoading(true);
              setItemLoading(true);
              // handleClick && handleClick();
              const entryStates = [];
              const le = await loadExistingEntry({
                entryName: nug.entryName
              });

              entryStates.push({
                ...le,
                name: nug.entryName
              });
              if (!isEntryStates(entryStates)) {
                const error = `Invalid entry stats: ${JSON.stringify(entryStates)}`;
                console.error(error);
                throw new Error(error);
              }
              setItemLoading(true);
              setLoading(false);
              // set modal background color to nugget color
              setModalContent(entryStates);
              setShowModal(true);
            } catch (error) {
              console.error(error);
              setItemLoading(true);
              setLoading(false);
            }
          }}
          className={`retro__grid-box ${loading && 'blinking-element'}`}
          style={{ backgroundColor: getColorFromNugs(gridValues) || 'grey' }}
        ></button>
      </OverlayTrigger>
    );
  }
  const { day: weekday, date } = getDayDateTimeFromEntryDateTitle(gridValues[0].entryName);

  const allTags = [...gridValues.map((gridValue) => gridValue.tags).flat(), ...allNugTags];
  const isHiddenByTagFilter = tagFilter.length > 0 && tagFilter.some((tag) => !allTags.includes(tag));
  return (
    <OverlayTrigger
      overlay={<Tooltip id="retro-empty-day-tooltip">{`${weekday}, ${date}`}</Tooltip>}
      delay={0}
      placement="top"
    >
      {
        <button
          disabled={itemLoading}
          key={day}
          onClick={async () => {
            try {
              setLoading(true);
              setItemLoading(true);
              handleClick && handleClick();
              const entryStates = [];
              for (const gridValue of gridValues) {
                const le = await loadExistingEntry({
                  entryName: gridValue.entryName
                });

                entryStates.push({
                  ...le,
                  name: gridValue.entryName
                });
              }
              if (!isEntryStates(entryStates)) {
                const error = `[CalendarMobileView] Invalid entry stats: ${JSON.stringify(entryStates)}`;
                console.error(error);
                throw new Error(error);
              }
              setItemLoading(true);
              setLoading(false);
              return history.push({
                pathname: `/entry/${gridValues[0].entryName}`,
                state: { entryState: entryStates }
              });
            } catch (error) {
              console.error(error);
              setItemLoading(true);
              setLoading(false);
            }
          }}
          className={`retro__grid-box ${
            isHiddenByTagFilter ? 'retro__grid-box--hidden-by-tag-filter' : 'retro__grid-box--calendar-entry'
          } ${loading && 'blinking-element'}`}
        ></button>
      }
    </OverlayTrigger>
  );
};

const DayBoxPlaceHolder = () => {
  return (
    <div
      key={'placeholder'}
      className="retro__grid-box retro__grid-box--off-calendar"
      style={{ backgroundColor: 'white' }}
    ></div>
  );
};

const DayBoxEmpty = (props: { day: number; month: number; year: number }) => {
  const { day, date } = getDayDateTimeFromEntryDateTitle(
    `${props.year}${props.month.toString().padStart(2, '0')}${props.day.toString().padStart(2, '0')}000000000`
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={0}
      overlay={<Tooltip id="retro-empty-day-tooltip">{`${day}, ${date}`}</Tooltip>}
    >
      <div key={day} className="retro__grid-box retro__grid-box--empty"></div>
    </OverlayTrigger>
  );
};

const getModalContent = (entryStates: EntryStates) => {
  const { day, date, time } = getDayDateTimeFromEntryDateTitle(entryStates[0].name);
  const allSameEntry = entryStates.every((entryState) => entryState.name === entryStates[0].name);
  const dateString = entryStates.length === 1 || allSameEntry ? `${day}, ${date} at ${time}` : `${day}, ${date}`;
  const content = entryStates.map((entryState) => {
    return (
      <div className="text text--paragraph-1 margin-bottom-16">
        <div className="display-inline">{`${entryState.blocks
          .filter((block) => block.role === 'user')
          .map((block) => `${block.content}\n\n`)}`}</div>
      </div>
    );
  });
  const popover = (
    <div>
      <div className="text text--subheading text--bold margin-bottom-16">{dateString}</div>
      {content}
    </div>
  );
  return popover;
};

const getPopover = (nugs: Nugget[]) => {
  const { day, date, time } = getDayDateTimeFromEntryDateTitle(nugs[0].entryName);
  const allSameEntry = nugs.every((nug) => nug.entryName === nugs[0].entryName);
  const dateString = nugs.length === 1 || allSameEntry ? `${day}, ${date} at ${time}` : `${day}, ${date}`;
  const content = nugs
    .filter((_v, i) => i < 5)
    .map((nug, i) => {
      const { time } = getDayDateTimeFromEntryDateTitle(nug.entryName);
      return (
        <div key={`${i}-popover-content-key`}>
          <strong>{allSameEntry ? nug.category : `${time} - ${nug.category}`}</strong>: {nug.content}
        </div>
      );
    });

  if (nugs.length > 5) {
    content.push(
      <div key={`popover-content-overflow`} style={{ marginTop: '1em' }}>
        <strong>Click the box for more detail</strong>
      </div>
    );
  }
  const popover = (
    <Popover key={nugs[0].entryName} id="popover-basic">
      <Popover.Header key={nugs[0].entryName + '-1'} as="h3">
        {dateString}
      </Popover.Header>
      <Popover.Body key={nugs[0].entryName + '-2'}>
        {content.length > 100 ? content.slice(0, 100) + '...' : content}
      </Popover.Body>
    </Popover>
  );
  return popover;
};

const getColorFromNugs = (nugs: Nugget[]) => {
  if (nugs.length === 0) {
    return 'grey';
  }
  // if (nugs.length > 1) {
  //   return '#FFD700'; // gold
  // }
  return getCategoryColorFromCategoryKey(nugs[0].category);
};

const getCategoryColorFromCategoryKey = (key: string) => {
  return CATEGORY_LABELS.find((l) => l.key === key)?.color;
};
