import React, { useMemo, useState } from 'react';
import '@fontsource/inter';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-datepicker/dist/react-datepicker.css';
import '../css/App.css';
import '../css/Font.css';
import { useGeneralContext } from '../context';
import { DayMonthYear } from './grid/types';
import { dayMonthYearIsBetween, getYearMonthDayNumbersFromCreationDateString } from '../util/dateTime';
import { Nugget } from '../views/retrospective/types';

const orderTagsByFrequency = (
  entryTags: (
    | Nugget
    | {
        entryName: string;
        tags: string[];
      }
  )[]
) => {
  const tagFrequency = new Map();

  for (const entry of entryTags) {
    for (const tag of entry.tags) {
      tagFrequency.set(tag, (tagFrequency.get(tag) || 0) + 1);
    }
  }

  return Array.from(tagFrequency.entries())
    .sort((a, b) => b[1] - a[1])
    .map((entry) => entry[0]);
};

const TagFilter = (props: {
  tagFilter: string[];
  setTagFilter: React.Dispatch<React.SetStateAction<string[]>>;
  dateRange: { start: DayMonthYear; end: DayMonthYear };
  tagsByEntry: { entryName: string; tags: string[] }[];
}) => {
  const { tagFilter, setTagFilter, dateRange, tagsByEntry } = props;
  const context = useGeneralContext();
  const { nuggets } = context;
  const [filterValue, setFilterValue] = useState('');

  const uniqueTagsWithinRange = useMemo(() => {
    const userCreatedTagsWithinRange = tagsByEntry.filter((value) =>
      dayMonthYearIsBetween({
        dayMonthYear: getYearMonthDayNumbersFromCreationDateString(value.entryName),
        start: dateRange.start,
        end: dateRange.end
      })
    );
    const autoGeneratedTagsWithinRange = nuggets.filter((nug) =>
      dayMonthYearIsBetween({
        dayMonthYear: getYearMonthDayNumbersFromCreationDateString(nug.entryName),
        start: dateRange.start,
        end: dateRange.end
      })
    );
    return orderTagsByFrequency([...userCreatedTagsWithinRange, ...autoGeneratedTagsWithinRange]);
  }, [nuggets, tagsByEntry, dateRange]);

  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomDropdownMenu = React.forwardRef<any, any>(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setFilterValue(e.target.value)}
            value={filterValue}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child: any) =>
                !filterValue ||
                (child.props.children &&
                  (child.props.children.toLowerCase().startsWith(filterValue) ||
                    child.props.children.startsWith(filterValue)))
            )}
          </ul>
        </div>
      );
    }
  );

  const CustomToggle = React.forwardRef<any, any>(({ children, onClick }, ref) => (
    <button
      className="calendar__nav--filter text text--regular-weight text--paragraph-2"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));
  return (
    <Dropdown
      as={ButtonGroup}
      onSelect={(eventKey) => {
        if (eventKey === 'clear') {
          setTagFilter([]);
          return;
        }
        const clickedTag: any = eventKey;
        if (!clickedTag) {
          return;
        }
        // TODO user reducer for tagFilter
        // it is simply toggling the active property of an existing tag in the dropdown
        if (tagFilter.includes(clickedTag)) {
          return setTagFilter([...tagFilter.filter((tag) => tag !== clickedTag)]);
        }
        setTagFilter([...tagFilter, clickedTag]);
      }}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
        Tags <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft: '.6em' }} />
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomDropdownMenu}>
        {/* consider hiding clear selection if nothing chosen yet */}
        <Dropdown.Item eventKey="clear" key="clear">
          Clear Selection
        </Dropdown.Item>
        <Dropdown.Divider />
        {uniqueTagsWithinRange
          .filter((v) => v !== null)
          .map((tag, index) => {
            return (
              <Dropdown.Item eventKey={tag as string} key={index.toString()} active={tagFilter.includes(tag as string)}>
                {tag}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TagFilter;
