import React, { useState, useContext, useEffect } from 'react';
import '../css/App.css';
import { COUNTRY_CURRENCY_MAP, SupportedCurrencyCode } from '../views/choose-plan/data';
import { Nugget } from '../views/retrospective/types';
import { retrieveNuggets } from '../api/nuggets';

export type SubscriptionStatus = 'free' | 'free_trial' | 'premium_monthly' | 'premium_yearly';

type ContextParams = {
  nickname: string;
  setNickname: React.Dispatch<React.SetStateAction<string>>;
  subscribed: boolean;
  setSubscribed: React.Dispatch<React.SetStateAction<boolean>>;
  entryBackgroundColor: string;
  setEntryBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
  feedbackModalOpen: boolean;
  setFeedbackModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  plansModalOpen: boolean;
  setPlansModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  allTags: string[];
  setAllTags: React.Dispatch<React.SetStateAction<string[]>>;
  subscriptionStatus: SubscriptionStatus | 'lifetime' | null;
  setSubscriptionStatus: React.Dispatch<React.SetStateAction<SubscriptionStatus | 'lifetime' | null>>;
  currency: {
    code: SupportedCurrencyCode;
    symbol: string;
  };
  setCurrency: React.Dispatch<
    React.SetStateAction<{
      code: SupportedCurrencyCode;
      symbol: string;
    }>
  >;
  jwtIsSet: boolean;
  setJwtIsSet: React.Dispatch<React.SetStateAction<boolean>>;
  importationEmail: string | null;
  setImportationEmail: React.Dispatch<React.SetStateAction<string | null>>;
  nuggets: Nugget[];
  setNuggets: React.Dispatch<React.SetStateAction<Nugget[]>>;
  refreshNuggets: () => void;
};

// exported for testing only
const Context = React.createContext<ContextParams | null>(null);

function ContextProvider(props: { children: any }) {
  const { children } = props;
  const [nickname, setNickname] = useState<string>('');
  const [subscribed, setSubscribed] = useState(false);
  const [entryBackgroundColor, setEntryBackgroundColor] = useState<string>('#ffffff');
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  const [plansModalOpen, setPlansModalOpen] = useState<boolean>(false);
  const [allTags, setAllTags] = useState<string[]>([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatus | 'lifetime' | null>(null);
  const [currency, setCurrency] = useState(COUNTRY_CURRENCY_MAP['US']);
  const [jwtIsSet, setJwtIsSet] = useState(false);
  const [importationEmail, setImportationEmail] = useState<string | null>(null);
  const [nuggets, setNuggets] = useState<Nugget[]>([]);
  const [hasFetchedNuggets, setHasFetchedNuggets] = useState(false);

  const fetchNuggets = async () => {
    const nugs = await retrieveNuggets();
    setNuggets(nugs);
    setHasFetchedNuggets(true);
  };

  const refreshNuggets = () => {
    setHasFetchedNuggets(false);
  };

  useEffect(() => {
    if (!hasFetchedNuggets && jwtIsSet) {
      fetchNuggets();
    }
  }, [hasFetchedNuggets, jwtIsSet]);

  // TODO remove nickname from state, it never changes
  // TODO refactor out the state handler
  return (
    <Context.Provider
      value={{
        nickname,
        setNickname,
        subscribed,
        setSubscribed,
        entryBackgroundColor,
        setEntryBackgroundColor,
        feedbackModalOpen,
        setFeedbackModalOpen,
        plansModalOpen,
        setPlansModalOpen,
        allTags,
        setAllTags,
        subscriptionStatus,
        setSubscriptionStatus,
        currency,
        setCurrency,
        jwtIsSet,
        setJwtIsSet,
        importationEmail,
        setImportationEmail,
        nuggets,
        setNuggets,
        refreshNuggets
      }}
    >
      {children}
    </Context.Provider>
  );
}

// context consumer hook
const useGeneralContext = () => {
  // get the context
  const context = useContext(Context);

  if (!context) {
    throw new Error('useGeneralContext was used outside of its Provider');
  }

  return context;
};

export { ContextProvider, useGeneralContext, Context };
