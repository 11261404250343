import { useState } from 'react';
import { WeeklyColumnsGrid } from '../../components/grid/Grid';
import '../../css/App.css';
import '../exploration/chatWithJournal.css';
import NuggetCategoryFilter from './NuggetCategoryDropdown';
import './retrospective.css';
import { Nugget, categoryKeys } from './types';
import { useMediaQuery } from 'react-responsive';
import { daysAgo, getDayMonthYearFromDate } from '../../util/dateTime';

const ExtractedNuggets = (props: {
  nuggets: Nugget[];
  loading: boolean;
  setShowPremiumModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { nuggets, loading, setShowPremiumModal } = props;
  const [categoryIndex, setCategoryIndex] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' });
  const weeksToDisplay = isTabletOrMobile ? (isSmallMobile ? 9 : 13) : 26;
  const [range, setRange] = useState({
    start: getDayMonthYearFromDate(daysAgo(7 * weeksToDisplay)),
    end: getDayMonthYearFromDate(new Date())
  });
  return (
    <div className="retro__container">
      <div className="flex-row-space-between">
        <div className="text text--caption text--gradient-color">Extracted Nuggets</div>
        {!loading && (
          <NuggetCategoryFilter
            categoryIndex={categoryIndex}
            setCategoryIndex={setCategoryIndex}
            setShowPremiumModal={setShowPremiumModal}
          />
        )}
      </div>
      {loading ? (
        <span
          style={{ height: '5em', width: '5em' }}
          className="spinner-border"
          role="status"
          aria-hidden="true"
        ></span>
      ) : (
        <div className="retro--grid">
          <div>
            <WeeklyColumnsGrid
              tagFilter={[]}
              gridValues={nuggets.filter((nug) => nug.category === categoryKeys[categoryIndex])}
              range={range}
              setRange={setRange}
              weeksToDisplay={weeksToDisplay}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtractedNuggets;
