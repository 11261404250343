import '@fontsource/inter';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { PenTool } from 'react-feather';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { Api, queryGpt3, retrieveSubscription, upsertJournalEntry } from '../../api';
import { loadExistingEntry } from '../../components/calendar/calendar';
import {
  EntryBlock,
  EntryBlockWithContext,
  EntryStates,
  S3Entry,
  isEntryStates
} from '../../components/calendar/types';
import TagFilter from '../../components/TagFilter';
import { useAuthContext, useEntryContext, useGeneralContext } from '../../context';
import '../../css/App.css';
import {
  browserTimestampJournalEntryFormat,
  daysAgo,
  getDayMonthYearFromDate,
  getNumberOfDaysSinceCreationDateString,
  sameDay
} from '../../util/dateTime';
import './home.css';
import { GptPromptCard } from './GptPromptCard';
import CreateNewEntryButton from './CreateNewEntryButton';
import Navigation from '../../components/Nav/Nav';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import { userIsFree } from '../../util/subscription';
import { WeeklyColumnsGrid } from '../../components/grid/Grid';

const Home = () => {
  const context = useGeneralContext();
  const EntryContext = useEntryContext();
  const Auth = useAuthContext();

  const { signOut, user } = Auth;
  const history = useHistory();
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [isLoadingEntries, setIsLoadingEntries] = useState<boolean>(true);
  const [openingEntry, setOpeningEntry] = useState<boolean>(false);
  const [gptPrompt, setGptPrompt] = useState<EntryBlock[]>([]);
  const hideExtraContent = useMediaQuery({ query: '(max-width: 1430px)' });
  const [showModal, setShowModal] = useState(false);
  const initConversation: EntryBlockWithContext[] = [
    { context: [], role: 'assistant', content: "Hi there, I'm Jumble" },
    { context: [], role: 'assistant', content: 'I can generate prompts to help you write' },
    { context: [], role: 'assistant', content: 'What topic would you like to write about today?' }
  ];
  const [conversation, setConversation] = useState<EntryBlockWithContext[]>(initConversation);
  const [tagsByEntry, setTagsByEntry] = useState<
    {
      entryName: string;
      tags: string[];
    }[]
  >([]);
  const { nickname, allTags, setAllTags, subscriptionStatus, setSubscriptionStatus, jwtIsSet } = context;
  const { entries, setEntries, addEntry } = EntryContext;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1250px)' });
  const isSmallMobile = useMediaQuery({ query: '(max-width: 430px)' });
  const weeksToDisplay = isTabletOrMobile ? (isSmallMobile ? 9 : 13) : 26;
  const [range, setRange] = useState({
    start: getDayMonthYearFromDate(daysAgo(7 * weeksToDisplay)),
    end: getDayMonthYearFromDate(new Date())
  });

  const location = useLocation();

  if (!user.getSignInUserSession()) {
    window.alert('Your session has expired. Please log back in.');
    signOut();
  }

  // refresh subscription
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const refreshSub = async () => {
      try {
        const refreshSub = queryParams.get('refreshSub') === 'true';
        if (!refreshSub) {
          return;
        }
        const userSub = await retrieveSubscription();
        if (userSub?.data?.stripeCustomerId) {
          sessionStorage.setItem('stripeCustomerId', userSub?.data?.stripeCustomerId);
        }
        setSubscriptionStatus(userSub?.data?.status || null);
      } catch (err) {
        // TODO send error to backend error handler
      }
    };
    if (!jwtIsSet) {
      return;
    }
    refreshSub();
  }, [setSubscriptionStatus, location.search, jwtIsSet]);

  const dateFilter = new Date();

  const existingEntryToday = entries.find((entry) => sameDay(dateFilter, entry.name));

  // set entries
  // TODO move this into the context provider
  useEffect(() => {
    let isCanceled = false;
    const controller = new AbortController();
    const retrieveAndSetJournalEntries = async () => {
      try {
        !isCanceled && setIsLoadingEntries(true);
        const response = await Api.post('retrieveJournalEntries', {
          signal: controller.signal
        });
        if (response.data.entries.length === 0) {
          !isCanceled && setIsLoadingEntries(false);
          setEntries([]);
          setAllTags([]);
          return;
        }

        const incomingTags = response.data.tags || [];
        const s3Entries: S3Entry[] = response.data.entries;

        if (!isCanceled) {
          setAllTags(incomingTags);

          setTagsByEntry(s3Entries.map((entry) => ({ entryName: entry.entryName, tags: entry.tags })));
          const newEntries = s3Entries.map((entry) => ({ name: entry.entryName, imported: entry.imported || false }));

          setEntries(newEntries);
          setIsLoadingEntries(false);
        }
      } catch (err: any) {
        if (err instanceof AxiosError && err?.response?.status === 401) {
          return;
        }
        if (err.code === 'ERR_CANCELED') {
          return;
        }
        console.error(err);
        setIsLoadingEntries(false);
      }
    };
    if (jwtIsSet) {
      retrieveAndSetJournalEntries();
    }
    return () => {
      isCanceled = true;
      controller.abort();
    };
  }, [setAllTags, setEntries, jwtIsSet]);

  const navigateToDailyEntry = async () => {
    const { entry } = getEntryOnDate(entries, new Date());

    if (openingEntry) {
      return;
    }
    setOpeningEntry(true);
    if (entry) {
      try {
        let singleEntryState = [];
        for (const entry of entries) {
          if (!sameDay(new Date(), entry.name)) {
            continue;
          }
          const le = await loadExistingEntry({
            entryName: entry.name,
            setOpeningEntry
          });

          singleEntryState.push({
            ...le,
            name: entry.name
          });
        }

        if (gptPrompt.length > 0) {
          singleEntryState[singleEntryState.length - 1].blocks.push(...gptPrompt);
          singleEntryState[singleEntryState.length - 1].blocks.push({ role: 'user', content: '' });
        }

        if (!isEntryStates(singleEntryState)) {
          const error = `[navigateToDailyEntry] Existing Entry Invalid entryState: ${JSON.stringify(singleEntryState)}`;
          throw new Error(error);
        }

        setOpeningEntry(false);
        return history.push({
          pathname: `/entry/${entry.name}`,
          state: {
            entryState: singleEntryState
          }
        });
      } catch (err) {
        setOpeningEntry(false);
        window.alert(
          'There was an issue retrieving Journal Entry. Make sure you have internet and try again. If the issue persists, let us know at support@jumblejournal.org'
        );
        return;
      }
    }

    // entry does not exist
    const creationDateTitle = browserTimestampJournalEntryFormat(new Date());
    const entryData: EntryStates = gptPrompt
      ? [
          {
            blocks: [...gptPrompt, { role: 'user', content: '' }],
            imported: false,
            name: creationDateTitle,
            tags: []
          }
        ]
      : [
          {
            blocks: [{ role: 'user', content: '' }],
            imported: false,
            name: creationDateTitle,
            tags: []
          }
        ];

    if (!isEntryStates(entryData)) {
      const error = `[navigateToDailyEntry] New Entry Invalid entryData: ${JSON.stringify(entryData)}`;
      throw new Error(error);
    }

    try {
      await upsertJournalEntry({
        value: JSON.stringify(entryData),
        creationDateTitle,
        embedEntry: false
      });
    } catch (error: any) {
      if (error instanceof AxiosError && error?.response?.status === 404) {
        window.alert('Unable to save because you have been logged out. Please log back in.');
      }
    }

    addEntry(creationDateTitle, false);

    // pass text and tags into the entry component ***###
    return history.push({
      pathname: `/entry/${creationDateTitle}`,
      state: { entryState: entryData }
    });
  };

  const streak = calculateStreak(entries);
  const consistency = calculateConsistency(entries);
  const timeOfDay = new Date().getHours();
  return (
    <div className="home__container">
      <PremiumUpsellModal
        showModal={showModal}
        setShowModal={setShowModal}
        message="Get access to personalized journaling prompts."
      />
      <Navigation />
      <div className="home__main-section">
        <div className="home__main-section--row">
          <div className="home__main-section--header">
            <div className="text text--regular-weight text--heading-2">
              <span>
                {timeOfDay < 12 ? 'Good Morning' : timeOfDay < 16 ? 'Good Afternoon' : 'Good Evening'}
                {nickname ? `, ${nickname}` : ''}{' '}
                <span role="img" aria-label="ear">
                  👋
                </span>
              </span>
            </div>
            <CreateNewEntryButton
              btnText={existingEntryToday ? 'Continue Writing' : 'Write a new Entry'}
              className="home__cta text text--semi-bold text--paragraph-2"
              navigateToDailyEntry={navigateToDailyEntry}
              disabled={isLoadingEntries || openingEntry}
            />
          </div>
          <div className="home__main-section--section-1">
            <GptPromptCard
              navigateToDailyEntry={navigateToDailyEntry}
              isLoadingEntries={isLoadingEntries}
              conversation={conversation}
              clearConversation={() => {
                setConversation(initConversation);
                setGptPrompt([]);
              }}
              handleUserQuery={async (newConversation: EntryBlockWithContext[]) => {
                if (userIsFree(subscriptionStatus)) {
                  setShowModal(true);
                  return;
                }
                const userQuery = newConversation.slice(-1)[0].content;
                const result = await queryGpt3({ prompt: userQuery });
                if (!result || !result.data) {
                  return;
                }

                const response = result.data?.gptResponse;

                if (!response) {
                  window.alert(
                    'There was an issue generating a journaling prompt. Please try again. Let us know if the issue persists: support@jumblejournal.org '
                  );
                  return;
                }

                setConversation([...newConversation, { context: [], role: 'assistant', content: response }]);
                setGptPrompt([
                  ...newConversation.slice(initConversation.length),
                  { context: [], role: 'assistant', content: response } as EntryBlockWithContext
                ]);
              }}
            />
            {hideExtraContent || (
              <div className="home__content-container">
                <ContentCard
                  category="TWEET"
                  title="Getting Started"
                  snippet="Journaling improves mood, memory and learning. But how do you start?"
                  link="https://twitter.com/tony_oreglia/status/1621524497315819520?s=20&t=JdKh5s5WY8WBIbC4mwZd8A"
                />
                <ContentCard
                  category="YOUTUBE"
                  title="Journaling provides clarity"
                  snippet="What do I want out of life, who do I want to be with?"
                  link="https://www.youtube.com/watch?v=BeiDiuf6Mmo"
                />
              </div>
            )}
          </div>
        </div>
        <div className="home__main-section--row">
          <div className="home__history-header-container">
            <div className="home__history-header text text--regular-weight text--heading-3-small">
              <PenTool className="home__history-header-pen-icon" />
              Writing
            </div>
            <div className="home__search-container hide-on-mobile">
              <TagFilter
                tagFilter={tagFilter}
                setTagFilter={setTagFilter}
                dateRange={range}
                tagsByEntry={tagsByEntry}
              />
            </div>
          </div>
          {isLoadingEntries ? (
            <div style={{ gridColumn: 2, marginLeft: '5rem' }}>
              <span
                style={{ textAlign: 'center', height: '5em', width: '5em', marginBottom: '50vh' }}
                className="spinner-border"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : (
            <div className="home__grid-stats-container">
              <div className="flex-column-centered">
                <WeeklyColumnsGrid
                  tagFilter={tagFilter}
                  gridValues={tagsByEntry}
                  handleClick={() => {
                    setOpeningEntry(true);
                  }}
                  dateRangeSelectorOnTop={true}
                  range={range}
                  setRange={setRange}
                  weeksToDisplay={weeksToDisplay}
                />
              </div>

              <div className="home__history-stats-container">
                <div className="home__stat-card">
                  <div className="text text--bold text--heading-2-small text--gradient-color">{`${streak} Day`}</div>
                  <div className="text text--bold text--paragraph-2 text--neutral-500">Streak</div>
                </div>
                <div className="home__stat-card">
                  <div className="text text--bold text--heading-2-small text--gradient-color">
                    {allTags.filter((v) => v !== null).length}
                  </div>
                  <div className="text text--bold text--paragraph-2 text--neutral-500">Tags used</div>
                </div>
                <div className="home__stat-card">
                  <div className="text text--bold text--heading-2-small text--gradient-color">{`${consistency}%`}</div>
                  <div className="text text--bold text--paragraph-2 text--neutral-500">Consistency</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ContentCard = (props: { category: 'TWEET' | 'YOUTUBE'; title: string; snippet: string; link: string }) => {
  const { category, title, snippet, link } = props;
  return (
    <div className="home__content-card" onClick={() => {}}>
      <a className="home_content-card-link" target="_blank" rel="noreferrer" href={link}>
        <div style={{ marginBottom: '8px' }} className="text--caption text--bold text--gradient-color">
          {category}
        </div>
        <div style={{ marginBottom: '8px' }} className="text text--semi-bold text--subheading text--primary-800">
          {title.slice(0, 36)}
        </div>
        <div className="text text--regular-weight text--paragraph-2 text--primary-900">{snippet.slice(0, 75)}</div>
      </a>
    </div>
  );
};

const getEntryOnDate = (entries: { name: string; imported: boolean }[], date: Date) => {
  const todayDate = getDayMonthYearFromDate(date);
  let index: number = -1;
  const entry = entries.find((entry, i) => {
    if (sameDay(todayDate, entry.name)) {
      index = i;
      return true;
    }
    return false;
  });
  return { entry, index };
};

const calculateConsistency = (entries: { name: string }[]) => {
  if (!entries || entries.length === 0) {
    return 0;
  }
  const totalDays = getNumberOfDaysSinceCreationDateString(entries[0].name);
  return Math.ceil((entries.length / totalDays) * 100);
};

const calculateStreak = (entries: { name: string; imported: boolean }[]) => {
  const yesterday = daysAgo(1);
  let streak = 0;
  const { entry, index } = getEntryOnDate(entries, yesterday);
  if (entry !== undefined && index > -1) {
    streak++;
    let checkDate = daysAgo(streak + 1);
    while (index - streak >= 0 && sameDay(checkDate, entries[index - streak].name)) {
      streak++;
      checkDate = daysAgo(streak + 1);
    }
  }
  // otherwise return streak (adding one if there is an entry today)
  const finalStreak = getEntryOnDate(entries, new Date()).entry ? streak + 1 : streak;
  return finalStreak;
};

export default Home;
