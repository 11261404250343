import { useEffect, useState } from 'react';
import { Map, RefreshCw } from 'react-feather';
import Navigation from '../../components/Nav/Nav';
import PremiumUpsellModal from '../../components/PremiumUpsellModal';
import { useGeneralContext } from '../../context';
import '../../css/App.css';
import '../exploration/chatWithJournal.css';
import './retrospective.css';
import { Nugget, categoryKeys } from './types';
import ExtractedNuggets from './ExtractedNuggets';
import TreeMap from './TreeMap';
import { retrieveNuggets } from '../../api/nuggets';

const Retrospective = () => {
  const { nuggets, setNuggets, refreshNuggets } = useGeneralContext();
  // const [nuggets, setNuggets] = useState<Nugget[]>([]);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshBtnRotating, setRefreshBtnRotating] = useState(false);

  const handleRefreshClick = () => {
    setRefreshBtnRotating(true);
    setTimeout(() => setRefreshBtnRotating(false), 1000);
    refreshNuggets();
  };

  return (
    <div className="home__container">
      <Navigation />
      <PremiumUpsellModal
        showModal={showPremiumModal}
        setShowModal={setShowPremiumModal}
        message={`Get access to your extracted ${categoryKeys
          .slice(1, categoryKeys.length - 1)
          .map((v) => `${v}s`)
          .join(', ')}, and ${categoryKeys[categoryKeys.length - 1]}s with a premium subscription`}
      />
      <div className="explore__container">
        <div className="explore__wrapper">
          <div className="text text--bold text--subheading explore__main-title">
            <div className="flex-row-space-between ">
              <div>
                <Map className="side-nav__item-logo text--primary-800" />
                Explore your Journal
              </div>
              <button onClick={handleRefreshClick} className={`refresh-button ${refreshBtnRotating ? 'rotate' : ''}`}>
                <RefreshCw size={24} />
              </button>
            </div>
          </div>
          <ExtractedNuggets nuggets={nuggets} loading={loading} setShowPremiumModal={setShowPremiumModal} />
          <TreeMap nuggets={nuggets} loading={loading} setShowPremiumModal={setShowPremiumModal} />
        </div>
      </div>
    </div>
  );
};

export default Retrospective;
